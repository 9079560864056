import dayjs from 'dayjs';

const dateFormat = value => {
  return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
};

const dateFormatDay = value => {
  return dayjs(value).format('YYYY-MM-DD');
};

const amountFormat = value => {
  return value.toLocaleString('en-US');
};

const telephone = value => {
  const reg = /^(\d{3})\d{4}(\d{4})$/;
  return value.replace(reg, '$1****$2');
};

// 节流
const throttle = (fn, time) => {
  let timer = null;
  time = time || 1000;
  return function(...args) {
    if (timer) {
      return;
    }
    const _this = this;
    timer = setTimeout(() => {
      timer = null;
    }, time);
    fn.apply(_this, args);
  };
};

const parseTime = (time, cFormat) => {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'undefined' || time === null || time === 'null') {
    return ''
  } else if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return [ '日', '一', '二', '三', '四', '五', '六' ][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
// 下载文件 
const downloadFile = (obj, name, suffix) => {
  const url = window.URL.createObjectURL(new Blob([ obj ]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  const fileName = parseTime(new Date()) + '-' + name + '.' + suffix
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export {
  dateFormat,
  dateFormatDay,
  amountFormat,
  telephone,
  throttle,
  downloadFile,
  parseTime
};
