<!--
 * @Author: xiaohe
 * @LastEditors: xiaohe
-->
<template>
  <div>
    <a-modal v-model="visibles" class="modal_a" title="提示信息">
      <p>为了投保顺利，请先完成企业认证!</p>
      <template v-slot:footer>
        <a-button @click="goTo" type="primary">去完善 </a-button>
        <a-button @click="goOut">暂不完善</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { logout } from "@/api/login/register.js";

export default {
  props: ["visible"],
  inject: ["reload"],
  components: {},
  data() {
    return {};
  },
  computed: {
    visibles: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {},
  methods: {
    goTo() {
      this.$router.push({ name: "entCertForm" });
      this.visibles = false;
    },
    goOut() {
      logout().then((res) => {
        if (res.data.status === "SUCCESS") {
          sessionStorage.clear();
          localStorage.clear();
          this.reload();
          window.location.href = "/login";
          localStorage.removeItem("token");
          this.visibles = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .ant-modal-close-x {
  display: none;
}
</style>
