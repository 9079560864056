import Vue from "vue";
import VueRouter from "vue-router";
import BasicLayout from "@/layouts/BasicLayout";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/", // 首页
    name: "index",
    redirect: "/index",
    // meta: {
    //   isHide: 1,
    // },
    // component: () => import('../views/index'),
  },
  {
    path: "/userLogin", // 用户
    name: "userLogin",
    meta: {
      isHide: 1,
      // isOpen: 3,
    },
    component: () => import("../views/userInfo"),
  },
  {
    path: "/index", // 首页
    name: "index2",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/index"),
  },
  {
    path: "/bidSecurity", // 投标保函
    name: "bidSecurity",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/bidSecurity"),
  },
  {
    path: "/bidStep",
    name: "bidStep",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/bidStep"),
  },
  {
    path: "/performStep", // 履约保函
    name: "performStep",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/performStep"),
  },
  {
    path: "/performance",
    name: "performance",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/performance"),
  },
  {
    path: "/advanceCharge", // 预付款保函
    name: "advanceCharge",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/advanceCharge"),
  },
  {
    path: "/quality", // 质量保函
    name: "quality",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/quality"),
  },
  {
    path: "/migrantWorkers", // 农民工工资保函
    name: "migrantWorkers",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeServe/migrantWorkers"),
  },
  {
    path: "/orderDetail", // 查看订单
    name: "orderDetail",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/orderCenter/orderDetail"),
  },
  {
    path: "/payIndex", // 支付页面
    name: "payIndex",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/orderCenter/payIndex"),
  },
  {
    path: "/applyinformation", // 申请信息
    name: "applyinformation",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/orderCenter/applyInformation"),
  },
  {
    path: "/guaranteeTruth", // 保函验真
    name: "guaranteeTruth",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/guaranteeTruth"),
  },
  {
    path: "/guaranteePhone", // 保函验真
    name: "guaranteePhone",
    component: () => import("../views/guaranteePhone"),
  },
  {
    path: "/brandintroduction", // 关于我们
    name: "brandintroduction",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/brandintroduction"),
  },
  {
    path: "/industryTrends", // 行业动态
    name: "industryTrends",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/industryTrends"),
  },
  {
    path: "/advantage", // 我们的优势
    name: "advantage",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/advantage"),
  },
  {
    path: "/pay", // 支付页面
    name: "pay",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/pay"),
  },
  {
    path: "/insurePay", // 支付页面
    name: "insurePay",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/insurePay"),
  },
  {
    path: "/contact", // 联系我们
    name: "contact",
    meta: {
      isHide: 1,
    },
    component: () => import("../views/contact"),
  },
  {
    path: "/login",
    name: "login",
    component: BasicLayout,
    // redirect: '/login',
    children: [
      {
        path: "/login", // 登录
        name: "login",
        meta: {
          isHide: 1,
          // isOpen: 3,
        },
        component: () => import("../views/login"),
      },
      {
        path: "/forgotPassword", // 忘记密码
        name: "forgotPassword",
        component: () => import("../views/forgotPassword"),
      },
      {
        path: "/register", // 注册
        name: "register",
        component: () => import("../views/register"),
      },
      {
        path: "/entCertForm", // 企业认证表单
        name: "entCertForm",
        component: () => import("../views/entCertification/form.vue"),
      },
      {
        path: "/entCertification", // 企业认证表单
        name: "entCertification",
        component: () => import("../views/entCertification"),
      },
      {
        path: "/orderCenter", // 订单中心
        name: "orderCenter",
        meta: {
          isHide: 1,
        },
        component: () => import("../views/orderCenter"),
      },
      {
        path: "/user", // 账户管理
        name: "user",
        meta: {
          isHide: 1,
        },
        component: () => import("../views/user"),
      },
      {
        path: "/premiumDeposit", // 保费预缴
        name: "premiumDeposit",
        meta: {
          isHide: 1,
        },
        component: () => import("../views/premiumDeposit"),
      },
      {
        path: "/lineCredit", // 授信额度
        name: "lineCredit",
        meta: {
          isHide: 1,
        },
        component: () => import("../views/lineCredit"),
      },
      {
        path: "/insure/project", // 申请保函
        name: "Project",
        meta: {
          isHide: 1,
        },
        component: () => import("../views/project"),
      },
      {
        path: "/insure/project/view",
        name: "ProView",
        meta: {
          isHide: 1,
        },
        component: () => import("../views/project/components/view.vue"),
      },
      {
        path: "/bankInsure",
        name: "bankInsure",
        component: () => import("../views/project/components/bankInsure.vue"),
      },
      {
        path: "/demo/insure",
        name: "Insure",
        component: () => import("../views/insure"),
      },
      {
        path: "/demo/insureConfirm",
        name: "insureConfirm",
        component: () => import("../views/insureConfirm"),
      },
      {
        path: "/demo/order",
        name: "order",
        component: () => import("../views/order"),
      },
      {
        path: "/demo/history",
        name: "history",
        component: () => import("../views/history"),
      },
      {
        path: "/insure",
        name: "Insure",
        meta: {
          isShow: 1,
          isHide: 1,
        },
        component: () => import("../views/insure"),
      },
      {
        path: "/insure/basicDoor",
        name: "BasicDoor",
        meta: {
          isShow: 1,
          isHide: 1,
        },
        component: () => import("../views/insure/components/BasicDoor.vue"),
      },
    ],
  },
  // 人脸识别结果页
  {
    path: "/faceRecognitionResult",
    name: "FaceRecognitionResult",
    meta: {
      isHide: 0
        },
    component: () => import("../views/insure/result"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
