<template>
  <div class="index">
    <div
      class="index-header"
    >
      <div
        class="head-welcome"
        style="color: #333;font-size: 30px;font-weight: 500;"
      >
        科创融资担保
        <!-- <img
          src="../assets/image/logo_head@2x.png"
          alt=""
        > -->
        <span v-if="$route.name === 'insurePay'" class="paytitle">收银台</span>
      </div>
      <div
        class="head-munu"
      >
        <a-menu
          :selected-keys="[$route.path]"
          mode="horizontal"
        >
          <a-menu-item :key="'/index'">
            <router-link to="/index">
              <span class="router-link">首页</span>
            </router-link>
          </a-menu-item>
          <!-- <a-sub-menu>
            <span
              slot="title"
              class="submenu-title-wrapper"
            >保函业务</span>
            <a-menu-item :key="'/bidSecurity'">
              <router-link to="bidSecurity">
                <span>投保保函</span>
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/performStep'">
              <router-link to="performStep">
                <span>履约保函</span>
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/advanceCharge'">
              <router-link to="advanceCharge">
                <span>预付款保函</span>
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/quality'">
              <router-link to="quality">
                <span>质量保函</span>
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/migrantWorkers'">
              <router-link to="migrantWorkers">
                <span>农民工工资保函</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu> -->
          <a-menu-item>
            <span
              class="router-link"
              @click="scroll"
            >公司简介</span>
          </a-menu-item>
          <a-menu-item :key="'/orderCenter'">
            <router-link to="/orderCenter">
              <span class="router-link">保函申请</span>
            </router-link>
          </a-menu-item>
          <a-menu-item :key="'/guaranteeTruth'">
            <router-link to="/guaranteeTruth">
              <span class="router-link">保函验真</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <span
              class="router-link"
              @click="scroll"
            >关于我们</span>
          </a-menu-item>
        </a-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    promise() {
      // 你的逻辑代码
      this.$router.push({ path: '/index' });
      return Promise.resolve();
    },
    scroll() {
      // const currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // 已经被卷掉的高度
      // const clientHeight = document.documentElement.clientHeight; // 浏览器高度
      this.promise().then(() => {
        this.$nextTick(() => {
          const scrollHeight = document.documentElement.scrollHeight; // 总高度
          window.scrollTo(0, scrollHeight);
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.index-header {
  height: 100px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1180px;
  margin: 0 auto;
  .head-welcome {
    color: #999999;
    min-width: 200px;
    img {
      width: 150px;
      height: 50px;
    }
  }
  .head-munu {
    margin-left: 300px;
  }
}
::v-deep .ant-menu-horizontal {
  border-bottom: none !important;
  font-size: 18px;
}
::v-deep .ant-menu-item,
.ant-menu-submenu-title {
  font-size: 18px;
}
::v-deep .ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0px !important;
  border-bottom: none !important;
}
::v-deep .ant-menu-submenu-selected, .ant-menu-horizontal > .ant-menu-item-selected > a{
  color: #2e4e9a !important;
  font-weight: 600;
}
::v-deep .ant-menu-submenu-title:hover,.ant-menu-item-active > a,.ant-menu-submenu-open .ant-menu-submenu-title .submenu-title-wrapper{
  color: #2e4e9a !important;
}
.paytitle {
  padding-left: 60px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
}
</style>
