<template>
  <div
    class="layoutFooter"
  >
    <!-- <img
      src="../assets/image/logo_head@2x.png"
      alt=""
    > -->
    <!-- <div style="color: #fff;padding-left: 20px;font-size: 20px;font-weight: 700;width:175px">
      科创融资担保
    </div>
    <div class="phone">
      <span v-html="'业务咨询：0769-22863128&emsp;&emsp;&emsp;&nbsp;&nbsp;'" />
      <span v-html="'地&emsp;址：广东省东莞市松山湖园区新城路5号1栋808室'" />
    </div> -->
    
    <div style="display:flex;height: 80px;color: #B6C1D4;flex-direction: row;">
      <!-- <span v-html="'&emsp;&emsp;&emsp;Copyright © 2022-2022 Js-lzb.com All Rights Reserved'" /> -->
      <span>Copyright © 2023-2025 All Rights Reserved 
        <a
          style="margin-left: 0"
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >&nbsp;&nbsp;&nbsp;&nbsp; 粤ICP备2020119530号-3</a>
        <!-- <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32090302000377"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
        ><img
          src="../assets/image/备案图标.png"
          style="float:left;"
        ><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">苏公网安备 32090302000377号</p></a> -->
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.layoutFooter{
    width: 100%;
    height: 110px;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      flex-direction: column;
      text-align: right;
      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 28px;
        display: flex;
        align-items: center;
        a {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 28px;
          margin-left: 14px;
        }
        img {
          width: 20px;
          height: 20px;
          margin: 0 5px 0 14px;
        }
      }
    }
    .phone {
      margin: 0 166px;
    }
    img {
      width: 150px;
      height: 50px;
    }
  }
</style>
